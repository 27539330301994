<template>
  <div class="answer-list-warp">
    <transition-group
      name="list-complete"
      tag="section"
      class="answer-list-warp-transition base-shadow"
      v-if="answersList.length > 0"
    >
      <div
        class="answer-box"
        v-for="(answerItem,index) in answersList"
        :key="answerItem.id"
      >
        <header class="answer-box-header">
          <div class="avatar-warp">
            <i class="el-icon-user-solid"></i>
          </div>
          <div class="answer-box-header-content">
            <div class="answer-name" :class="[answerItem.disabled ? 'disable-text': '']">
              <i class="el-icon-star-on text-start" v-if="answerItem.star"></i
              >&nbsp;
              {{ answerItem.createdByName }} {{answerItem.disabled ? '【该答案已禁用】' : ''}}
            </div>
            <div class="answer-date">
              编辑于 {{ answerItem.lastModifiedAt | date_time }}
            </div>
          </div>
        </header>
        <div class="answer-box-content" >
          <div class="answer-box-content-text" :class="[answerItem.disabled ? 'disable-text': '']">
            {{ answerItem.content }}
          </div>
          <div
            class="question-img-list"
            v-if="!answerItem.disabled && answerItem.files && answerItem.files.length > 0"
          >
            <template v-for="item in answerItem.files">
              <FilesItem
                :item="item"
                :showCheckBox="false"
                :showFileName="false"
                :showFileDesc="false"
                :key="item.id"
                class="mt-r"
              />
            </template>
          </div>
        </div>
        <footer class="answer-box-footer text-info">
          <i
            class="el-icon-edit footer-btn"
            :class="[
              answerItem.star
                ? 'el-icon-star-on text-start'
                : 'el-icon-star-off',
            ]"
            :title="answerItem.star ? '取消星标' : '设为星标'"
            @click="handleStart(answerItem)"
          ></i>
          <i
            class="el-icon-edit footer-btn"
            :title="answerItem.canUpdateChecking ? '权限检验中...' : '修改'"
            @click="handleEdit(answerItem)"
          ></i>
          <i
            class="el-icon-help footer-btn"
            v-if="answerItem.disabled"
            :title="answerItem.canDisableChecking ? '权限检验中...' : '启用'"
            @click="enableRow(answerItem,index)"
          ></i>
          <i
            class="el-icon-s-help footer-btn"
            v-if="!answerItem.disabled"
            :title="answerItem.canDisableChecking ? '权限检验中...' : '禁用'"
            @click="disableRow(answerItem ,index)"
          ></i>
        </footer>
      </div>
    </transition-group>
    <section
      class="list-pagination-wrap base-shadow"
      v-if="answersList.length > 0"
    >
      <el-pagination
        class="base-table-pagination"
        :disabled="loading"
        @size-change="handleSizeChange"
        @current-change="handlePageChange"
        :current-page="page + 1"
        :page-sizes="[20, 40, 60, 80]"
        :page-size="size"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </section>

    <div class="no-list-data" v-if="answersList.length <= 0">
      暂无回答，<span class="text-nav" @click="$emit('writeAnswer')" key="sdada"
        >我来写</span
      >第一个答案
    </div>
  </div>
</template>

<script>
import {
  GetAnswersList,
  ToggleMultipleDisable,
  ToggleStartAnswer,
  GetStarCheck,
  GetDiasbleCheck,
  GetUpdateCheck,
} from "../api/answers";
import { ShowApiError } from "@/request/error";
import { isEmprty } from "@/utils/validate";
import FilesItem from "@/views/filesModule/components/FilesItem";
export default {
  components: { FilesItem },
  props: {
    questionId: {
      type: [Number, String],
      default: null,
    },
  },
  data() {
    return {
      baseImgPath: "",
      loading: false,
      answersList: [],
      page: 0,
      size: 20,
      total: 0,
    };
  },
  watch: {
    questionId: {
      handler() {
        if (!isEmprty(this.questionId)) {
          this.getAnswerList();
        }
      },
      immediate: true,
      deep: true,
    },
  },
  methods: {
    handleSizeChange(val) {
      this.page = 0;
      this.size = val;
      this.getAnswerList();
    },
    handlePageChange(val) {
      this.page = val - 1;
      this.getAnswerList();
    },
    getAnswerList() {
      this.loading = true;
      GetAnswersList({
        page: this.page,
        size: 20,
        questionId: this.questionId,
      })
        .then((res) => {
          this.loading = false;
          let { content, totalElements } = res.data;
          this.answersList = content.map((item) => {
            return {
              ...item,
              canDisableChecking: false,
              canUpdateChecking: false,
              canStartChecking: false,
              canUpdate: false,
              canStart: false,
              canDisable: false,
            };
          });
          this.total = totalElements;
        })
        .catch((err) => {
          this.loading = false;
          ShowApiError("拉取答案错误", err);
        });
    },
    onRefresh() {
      this.page = 0;
      this.getAnswerList();
    },
    handleStart(item) {
      if (item.canStart) {
        ToggleStartAnswer(item.id)
          .then(() => {
             item.star = !item.star;
          })
          .catch((err) => {
            ShowApiError("星标设置请求错误", err);
          });
        return false;
      }
      item.canStartChecking = true
      GetStarCheck(item.id)
        .then((res) => {
          item.canStart = res.data;
          item.canStartChecking = false
          if (res.data) {
            ToggleStartAnswer(item.id)
              .then(() => {
                // item.star = !item.star;
                this.answersList = this.answersList.map(target => {
                  if(target.id !== item.id){
                    target.star = false
                  }else{
                    target.star = true
                  }
                  return target
                })
              })
              .catch((err) => {
                ShowApiError("星标设置请求错误", err);
              });
          } else {
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          item.canStartChecking = false
          item.canStart = false;
          this.$message.warning("权限校验异常，请稍后再试!");
        });
    },
    handleEdit(item) {
      if (item.canUpdate) {
        this.$emit("handleEdit", item);
        return false;
      }
      item.canUpdateChecking = true;
      GetUpdateCheck(item.id)
        .then((res) => {
          item.canUpdateChecking = false;
          item.canUpdate = res.data;
          if (res.data) {
            this.$emit("handleEdit", item);
          } else {
            this.$message.warning("没有权限执行此操作!");
          }
        })
        .catch(() => {
          item.canUpdateChecking = false;
          item.canUpdate = false;
          this.$message.warning("权限校验异常，请稍后再试!");
        });
    },
    disableRow(row,index) {
      this.ToggleDisable(row, true).then(() => {
        row.disabled = !row.disabled;
        this.$set(this.answersList, index, row);
      });
    },
    enableRow(row,index) {
      this.ToggleDisable(row, false).then(() => {
        row.disabled = !row.disabled;
        this.$set(this.answersList, index, row);
      });
    },
    ToggleDisable(row, flag = false) {
      return new Promise((resolve, reject) => {
        let title = flag ? "禁用提示" : "启用提示";
        let massage = flag ? "是否确定要禁用" : "是否确定要启用";
        row.canDisableChecking = true;
        GetDiasbleCheck(row.id)
          .then((res) => {
            if (res.data) {
              row.canDisableChecking = false;
              this.$msgbox({
                title: title,
                message: massage,
                showCancelButton: true,
                confirmButtonText: "确定",
                cancelButtonText: "取消",
                beforeClose: (action, instance, done) => {
                  if (action === "confirm") {
                    instance.confirmButtonLoading = true;
                    instance.confirmButtonText = "执行中...";
                    ToggleMultipleDisable([row.id], flag)
                      .then((res) => {
                        this.$message.success("操作成功!");
                        instance.confirmButtonLoading = false;
                        instance.confirmButtonText = "";
                        done();
                        resolve(res);
                      })
                      .catch((err) => {
                        done();
                        instance.confirmButtonLoading = false;
                        instance.confirmButtonText = "";
                        ShowApiError( "操作请求错误",err);
                        reject(err);
                      });
                  } else {
                    instance.confirmButtonLoading = false;
                    instance.confirmButtonText = "";
                    done();
                  }
                },
              });
            } else {
              row.canDisableChecking = false;
              this.$message.warning("没有权限执行此操作!");
              reject();
            }
          })
          .catch(() => {
            row.canDisableChecking = false;
            this.$message.warning("权限校验异常，请稍后再试");
            reject();
          });
      });
    },
    // handleDelete(item) {
    //   this.$msgbox({
    //     title: "删除提示",
    //     message: `是否确定要删除【${item.content}】`,
    //     showCancelButton: true,
    //     confirmButtonText: "确定",
    //     cancelButtonText: "取消",
    //     beforeClose: (action, instance, done) => {
    //       if (action === "confirm") {
    //         instance.confirmButtonLoading = true;
    //         instance.confirmButtonText = "执行中...";
    //         DeleteAnswersById(item.id)
    //           .then(() => {
    //             this.$message.success("操作成功!");
    //             instance.confirmButtonLoading = false;
    //             instance.confirmButtonText = "";
    //             done();
    //             this.onRefresh();
    //           })
    //           .catch((err) => {
    //             done();
    //             instance.confirmButtonLoading = false;
    //             instance.confirmButtonText = "";
    //             ShowApiError("操作请求错误", err);
    //           });
    //       } else {
    //         done();
    //       }
    //     },
    //   });
    // },
  },
};
</script>

<style lang="scss" scoped>
.answer-list-warp {
  box-sizing: border-box;
  width: 100%;
  margin-top: 20px;
  margin-bottom: 20px;
}
.answer-list-warp-transition {
  box-sizing: border-box;
  width: 100%;
  position: relative;
}
.list-pagination-wrap {
  box-sizing: border-box;
  width: 100%;
  padding: 15px 0;
  text-align: center;
  margin-top: 10px;
  background-color: #fff;
}
.no-list-data {
  text-align: center;
  font-size: 14px;
  color: #989898;
}
.answer-box {
  display: inline-block;
  box-sizing: border-box;
  width: 100%;
  padding: 20px;
  background-color: #fff;
  border-bottom: 1px solid #f1f1f1;
  transition: all 1s;
  .answer-box-header {
    box-sizing: border-box;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-bottom: 20px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    .avatar-warp {
      box-sizing: border-box;
      flex: 50px 0 0;
      width: 50px;
      height: 50px;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 26px;
      background-color: #409eff;
      color: #fff;
      border-radius: 4px;
    }
    .answer-box-header-content {
      box-sizing: border-box;
      flex: 1;
      padding-left: 20px;
      .answer-name {
        font-size: 18px;
        font-weight: bold;
        color: #000;
        text-align: left;
        margin-bottom: 5px;
      }
      .answer-date {
        font-size: 14px;
        color: #989898;
      }
    }
  }
  .answer-box-content {
    box-sizing: border-box;
    padding-bottom: 15px;
    font-size: 15px;
    color: #333;
    min-height: 80px;
    .answer-box-content-text {
      line-height: 24px;
    }
  }
  .answer-box-footer {
    box-sizing: border-box;
    width: 100%;
    padding-top: 5px;
    text-align: right;
    .footer-btn {
      width: 26px;
      height: 26px;
      line-height: 26px;
      text-align: center;
      font-size: 18px;
      padding: 0 5px;
      cursor: pointer;
      transition: all 0.2s linear;
      &:hover {
        transform: scale(1.1);
      }
    }
  }
  .disable-text {
    text-decoration: line-through;
    color: #ddd !important;
    cursor: not-allowed;
  }
}
.answer-img-list {
  padding-top: 15px;
  position: relative;
  .question-img {
    width: 100px;
    height: 100px;
    border: 1px solid #d1d1d1;
    border-radius: 4px;
    margin-bottom: 5px;
    margin-right: 5px;
  }
}

.list-complete-enter, .list-complete-leave-to
/* .list-complete-leave-active for below version 2.1.8 */ {
  opacity: 0;
  transform: translateY(30px);
}
.list-complete-leave-active {
  position: absolute;
}
</style>