<template>
  <div class="quick-table-wrap">
    <div class="quick-table">
      <section class="filter-wrap">
        <el-form
          ref="form"
          :model="tableFilters"
          label-position="top"
          label-width="auto"
        >
          <el-row :gutter="20">
            <el-col :span="12">
              <el-form-item label="问题">
                <el-input
                  v-model="tableFilters.content"
                  placeholder="请输入"
                  clearable
                ></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="类型">
                <BasicSelect
                  v-model="tableFilters.typeId"
                  :allOptions="typeOptions"
                />
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="最后修改人">
                <BasicSelect
                  v-model="tableFilters.lastModifiedById"
                  :allOptions="userOptions"
                />
              </el-form-item>
            </el-col>
            <template v-if="showMoreFilter">
              <el-col :span="12">
                <el-form-item label="创建日期">
                  <el-date-picker
                    v-model="tableFilters.createdAt"
                    type="daterange"
                    style="width: 100%"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['00:00:00', '23:59:59']"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="最后修改日期">
                  <el-date-picker
                    v-model="tableFilters.lastModifiedAt"
                    type="daterange"
                    style="width: 100%"
                    range-separator="至"
                    start-placeholder="开始日期"
                    end-placeholder="结束日期"
                    :default-time="['00:00:00', '23:59:59']"
                  >
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="创建人">
                  <BasicSelect
                    v-model="tableFilters.createdById"
                    :allOptions="userOptions"
                  />
                </el-form-item>
              </el-col>
            </template>

            <el-col :span="24">
              <el-form-item style="text-align: right">
                <el-button type="primary" @click="onSearch">查询</el-button>
                <el-button @click="onResetFilter">重置</el-button>
                <el-button
                  type="text"
                  @click="showMoreFilter = !showMoreFilter"
                >
                  更多
                  <i
                    :class="
                      showMoreFilter ? 'el-icon-arrow-up' : 'el-icon-arrow-down'
                    "
                  ></i>
                </el-button>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form>
      </section>
      <el-table :data="tableData" style="width: 100%">
        <el-table-column
          prop="content"
          label="问题"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div>
              <span
                title="点击查看详情"
                class="text-nav"
                @click="onShowDetail(scope.row)"
                >{{ scope.row.content }}</span
              >
            </div>
            <!-- <div class="text-info">
            类型：{{ scope.row.typeName ? scope.row.typeName : "暂无" }}
          </div> -->
          </template>
        </el-table-column>
        <el-table-column prop="typeName" label="类型" align="right">
        </el-table-column>
        <el-table-column
          prop="createdByName"
          width="120px"
          label="创建人"
          align="right"
          :show-overflow-tooltip="true"
        >
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      class="base-table-pagination"
      :disabled="tableLoading"
      @current-change="handleCurrentChange"
      :current-page="page + 1"
      :page-size="size"
      layout="total, prev, pager, next"
      :total="total"
    >
    </el-pagination>
  </div>
</template>

<script>
import { GetQuestionsList } from "../api/question";
import { ShowApiError } from "@/request/error";
import BasicSelect from "@/components/BasicSelect";
import { onClearFilter } from "@/utils/common";
import { GetQuestionsTypeAll } from "@/views/questions/questionsTypes/api";
export default {
  components: { BasicSelect },
  data() {
    return {
      tableLoading: false,
      page: 0,
      size: 30,
      tableData: [],
      total: 0,
      showMoreFilter: false,
      typeOptions: [],
      tableFilters: {
        content: "",
        createdAt: null,
        lastModifiedAt: null,
        lastModifiedById: null,
        createdById: null,
        typeId: null,
      },
    };
  },
  created() {
    this.getTableData();
    this.getTypeOptions();
  },
  computed: {
    userOptions() {
      return this.$store.getters.userOptions;
    },
  },
  methods: {
    handleCurrentChange(val) {
      this.page = val - 1;
      this.getTableData();
    },
    getTableData() {
      this.tableLoading = true;
      GetQuestionsList({
        page: this.page,
        size: this.size,
        ...this.tableFilters,
      })
        .then((res) => {
          let { content, totalElements } = res.data;
          this.tableData = content;
          this.total = totalElements;
          // 加载状态结束
          this.tableLoading = false;
        })
        .catch((err) => {
          this.tableLoading = false;
          ShowApiError("拉取问题列表错误", err);
        });
    },
    onShowDetail(row) {
      this.$emit("onChange", row.id);
      this.$router.replace({
        name: "QuestionDetail",
        params: {
          id: row.id,
        },
      });
    },
    onSearch() {
      this.page = 0;
      this.getTableData();
    },
    onResetFilter() {
      this.tableFilters = onClearFilter(this.tableFilters);
      this.getTypeOptions();
      this.page = 0;
      this.getTableData();
    },
    getTypeOptions() {
      GetQuestionsTypeAll().then((res) => {
        this.typeOptions = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-wrap {
  box-sizing: border-box;
  margin-bottom: 15px;
  padding: 15px;
  ::v-deep {
    .el-form-item__label {
      line-height: 24px;
    }
    .el-form-item {
      margin-bottom: 20px;
    }
  }
}
.quick-table-wrap {
  box-sizing: border-box;
  height: 100%;
  padding-bottom: 68px;
  position: relative;
}
.quick-table {
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
}
.base-table-pagination {
  box-sizing: border-box;
  padding: 20px 10px;
  text-align: center;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid #f1f1f1;
}
</style>